import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileViewComponent from '@/core/shared/file-view/file-view.component.vue';

@Options({
    name: 'file-input-component',
    props: {
        viewFiles: {
            type: Boolean,
            default: true,
        },
        fileUrl: {
            type: [String, null],
            required: false,
        },
        sentFile: {
            type: [File, null],
            required: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        fileNameStore: {
            type: Object,
            required: false,
        },
        fileNameStoreArr: {
            type: Array,
            required: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        limit: {
            type: [Boolean, Number],
            default: false,
        },
        deleteIndex: {
            type: Number,
            required: false,
        },
        accept: {
            type: String,
            required: false,
            default: ''
        }
    },
    components: {
        FileViewComponent
    },
    watch: {
        deleteIndex: {
            handler: 'onDeleteIndex',
            immediate: false,
            deep: false,
        },
        fileNameStoreArr: {
            handler: 'onFileNameStoreArr',
            immediate: false,
            deep: false,
        },
    }
})

export default class FileInputComponent extends Vue {
    public limit: boolean | number = false;
    public fileNameStore = {};
    public fileNames: any[] = [];
    public file: string = '';
    public fileArray: any[] = [];
    public fileUrl: string = '';
    public sentFile: File | null = null;
    public multiple: boolean = false;
    public limitError = false;
    public $refs!: {
        file: HTMLInputElement,
    };


    public mounted() {
        if (this.fileNameStore && !this.fileNames?.length) {
            this.fileNames.push(this.fileNameStore);
        }

    }

    public onFileNameStoreArr(val: any) {
        this.fileNames = val;
        this.fileArray = val;
    }

    public onDeleteIndex(val: any) {
        if (this.fileArray.length) {
            this.fileArray.splice(val, 1);
        }
    }


    public checkFileName($event: any): void {
        if (!this.multiple) {
            this.fileArray = [];
            this.fileNames = [];
            this.file = $event.target.files[0];
            this.$emit('file', this.file);
            this.fileArray?.push($event.target.files[0]);
            this.fileNames = this.fileArray;

        } else {
            if (this.limit !== false) {
                if (this.fileArray.length < this.limit) {
                    let different = (Number(this.limit) - this.fileArray.length);
                    for (let key in $event.target.files) {
                        if (different > 0) {
                            if (typeof $event.target.files[key] === 'object') {
                                this.fileArray?.push($event.target.files[key]);
                                this.fileNames = this.fileArray;
                            }
                            different = --different;
                        } else {
                            this.limitError = true;
                        }
                    }
                }
            } else {
                for (let key in $event.target.files) {
                    if (typeof $event.target.files[key] === 'object') {
                        this.fileArray?.push($event.target.files[key]);
                        this.fileNames = this.fileArray;
                    }
                }
            }

            this.$emit('file', this.fileArray);
        }
    }

    public deleteSendFile(): void {
        this.$emit('deleteFile');
    }

    public resetFile(): void {
        // this.$refs.file?.value = '';
        this.fileNames = [];
        this.file = '';
        this.$emit('resetFile');
    }

    public deleteFile(index: any) {
        if (!this.multiple) {
            this.file = '';
            this.fileNames = [];
            // this.$refs.file.value = '';
            this.$emit('file', null);
        } else {
            if (this.fileArray?.length) {
                this.fileArray.splice(index, 1);
                this.$emit('file', this.fileArray);
            }
        }
    }
}
