<style src="./progressbar.component.scss" lang="scss"></style>

<template>
  <div class="progressbar d-none d-md-flex">
    <div class="progressbar__step"
         v-for="(step, index) in stepDescription"
         :key="index"
         :class="(step.number === current)?'progressbar__step-active--main':'',
         (step.number < current)?'progressbar__step-active':''"
    >
      <div class="progressbar__step-rectangle"
           v-if="step.number !== 1"
           :class="(step.number <= current)?'progressbar__step-rectangle-active':''"
      ></div>
      <div class="progressbar__step-number">
        <div class="progressbar__step-text">
          {{ step.number }}
        </div>
      </div>
      <div class="progressbar__step-descr">
        {{ step.description }}
      </div>
    </div>
  </div>
  <div class="progressbar-mobile d-block d-md-none">
    <div class="progressbar-mobile__step-count">
      {{ current }}/{{ step }}
    </div>
    <div class="progressbar-mobile__wrapper">
      <div class="progressbar-mobile__present" :style="{'width':`${present}%`}"></div>
    </div>
    <v-divider/>
  </div>
</template>

<script src="./progressbar.component.ts"></script>
