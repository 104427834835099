import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';

@Options({
    name: 'progressbar-component',
    props: {
        step: {
            type: Number,
            required: true,
        },
        current: {
            type: Number,
            required: true,
        },
        stepDescription: {
            type:Array,
            required:true,
        }
    }
})

export default class ProgressbarComponent extends Vue {
    public step: number = 1;
    public current: number = 1;
    public stepDescription:Array<Object> = [];


    get present(): number {
        return Math.round(100 * this.current / this.step);
    }
}
