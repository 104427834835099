<style src="./file-input.component.scss" lang="scss"></style>

<template>
  <div class="file-input">
    <div class="file-input__img d-flex align-start">
      <img v-if="fileUrl"
           :src="fileUrl"
           alt="icon" class="ml-15 mb-15 icon-url"/>
      <button
          v-show="fileUrl"
          @click="deleteSendFile"
          type="button"
      >
      </button>
    </div>
    <label v-if="! ((fileNames?.length === 1) && !multiple)"
           :class="{'v-file-active':fileNames?.length, 'v-file--error':error}"
           for="file" class="v-label text-center">
              <span class="file-input__name">
              Faylı seçin və ya sürüşdürün
              </span>

      <button
          v-show="fileNames?.length"
          @click="resetFile"
          type="button"
      >
      </button>
    </label>
    <div class="d-flex flex-wrap file-view-list" v-if="fileNames?.length && viewFiles">
      <file-view-component
          @deleteFile="deleteFile(index)"
          class="mt-5 mr-10" v-for="(item , index) in fileNames" :key="index" :fileSelect="item"/>
    </div>
    <input
        :accept="accept"
        v-if="! ((fileNames?.length === 1) && !multiple)"
        :multiple="multiple"
        type="file"
        id="file"
        ref="file"
        @change="checkFileName"
    >
  </div>

</template>

<script src="./file-input.component.ts"></script>
