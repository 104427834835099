import {Vue} from 'vue-class-component';
import {isArray} from 'rxjs/internal-compatibility';

export class CreateFormData extends Vue {
    public createFormData(data: any): FormData {
        const formData = new FormData();
        for (let key in data) {
            if ( !isArray(data[key]) && (typeof data[key] === 'object')) {
                if (data[key]!==null) {
                    Object.entries(data[key]).forEach(([objKey, objItem]) => {
                        if (objItem !== null && objItem !== undefined) {
                            formData.append(`${this.capitalize(key)}.${this.capitalize(objKey)}`, String(objItem));
                        }
                    });
                } else  {
                    formData.append(`${this.capitalize(key)}`, String(data[key]));
                }


            }
            if (isArray(data[key])) {
                if (typeof data[key][0] === 'number') {
                    data[key].forEach((item: any, index: any) => {
                        formData.append(`${this.capitalize(key)}[${index}]`, item);
                    });
                }
                if (data[key].length > 0 && !(data[key][0].lastModifiedDate)) {
                    data[key].forEach((item: any, indexMain: any) => {
                        for (let keyArray in item) {
                            if (isArray(item[keyArray])) {
                                item[keyArray].forEach((item: any, index: any) => {
                                    formData.append(`${this.capitalize(key)}[${indexMain}].${this.capitalize(keyArray)}[${index}]`, item);
                                });

                            } else {
                                formData.append(`${this.capitalize(key)}[${indexMain}].${this.capitalize(keyArray)}`, item[keyArray]);
                            }
                        }
                    });
                } else {
                    if (data[key][0].lastModifiedDate) {
                        data[key].forEach((itemFile: any, indexFile: any) => {
                            formData.append(`${this.capitalize(key)}[${indexFile}]`, itemFile);
                        });
                    } else {
                        formData.append(`${this.capitalize(key)}`, data[key]);
                    }
                }
            } else {
                if (typeof data[key] === 'number' || typeof data[key] === 'string' || typeof data[key] === 'boolean')
                formData.append(`${this.capitalize(key)}`, data[key]);
            }
        }
        return formData;
    }

    public capitalize(str: any) {
        return str.replace(/(^|\s)\S/g, function (a: any) {
            return a.toUpperCase();
        });
    }
}

